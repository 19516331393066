<template>
<div>
  <div class="flex flex-col items-center justify-around p-4 sm:flex-row-reverse">
        <div class="flex flex-col items-center w-full">

          <p
            v-if="currentQuestion && showVideo"
            class="mb-4 text-gray-700 italic text-extrabold"
          >
            {{ currentQuestion }}
          </p>

          <RecordControls
            :recordingState="recordingState"
            :onResume="resumeRecording"
            :onPause="pauseRecording"
            :onStop="stopRecording"
            :onStart="startRecording"
            :pauseLabel="isLastQuestion ? 'Pause' : 'Next Question'"
          />

          <div class="mb-8" />

          <div class="flex flex-col items-center justify-center w-full pb-0">
            <div class="w-7/12 aspect-w-16 aspect-video relative p-2 rounded bg-blue-400">

              <video
                :class="showVideo ? 'w-full' : 'hidden'"
                autoPlay
                muted
                playsinline
                ref="videoElement"
              ></video>

              <div
                v-if="recordingState === 'idle' || recordingState === 'preparing-stream'"
                class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full bg-blue-400"
              >
                <div class="w-10 h-10 p-2 mr-4 bg-white rounded">
                  <AnimatedRecord />
                </div>
                <h2 class="text-xl ">
                  Getting Things Ready...
                </h2>
              </div>

              <div
                v-else-if="recordingState === 'finishing-upload'"
                class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full aspect-video bg-blue-400 bg-opacity-50"
              >
                <div class="w-10 h-10 p-2 mr-4 bg-white rounded">
                  <AnimatedRecord />
                </div>
                <h2 class="text-2xl">
                  Finalizing Recording...
                </h2>
              </div>

              <div
                v-if="showCounting"
                class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-400 bg-opacity-50"
              >
                <span class="text-4xl font-extrabold text-sw-red">
                  Record In {{ countIn }}
                </span>
              </div>
            </div>

            <div
              v-if="showVideo"
              class="flex justify-center mt-1 w-4/5 md:w-1/3"
            >
              <SpeakerWaveIcon class="w-4 h-4 mr-2 text-blue-400" />
              <div class="relative mt-0.5 h-3 w-3/4 max-w-xl overflow-hidden rounded-full">
                <div class="absolute w-full h-full bg-gray-200"></div>
                <div
                  id="bar"
                  class="relative h-full transition-all duration-100 ease-out bg-blue-400"
                  :style="`width: ${audioLevel}%`"
                ></div>
              </div>
            </div>
          </div>

          <div v-if="showDuration">
            <div class="mb-2 text-xl text-black">
              Duration: {{secondsToTimecode(calculateDuration(recordSlices))}}
            </div>
          </div>

          <div
            class="w-full"
            v-if="showControls"
          >
            <div>
              <DeviceSelector
                label="Video Device"
                :devices="deviceList.video"
                :devicesLoaded="devicesLoaded"
                :selectedDevice="selectedVideoDevice"
                :onDeviceChange="onCameraChange"
              />

              <DeviceSelector
                label="Audio Device"
                :devices="deviceList.audio"
                :devicesLoaded="devicesLoaded"
                :selectedDevice="selectedAudioDevice"
                :onDeviceChange="onMicChange"
              />
            </div>

          </div>
        </div>
      </div>
</div>



</template>




<script setup>
import { SpeakerWaveIcon } from "@heroicons/vue/24/solid";

import AnimatedRecord from './AnimatedRecord.vue';
import RecordControls from './RecordControls.vue';
import DeviceSelector from './DeviceSelector.vue';

import {
  computed,
  onMounted,
  ref,
  reactive,
  watch,
} from 'vue';

import axios from 'axios';

const props = defineProps({
  onCompleteUrl: {
      type: String,
      required: true,
  },
  questions: {
    type: Array,
    required: false,
    default: [],
  }
});

const currentQuestionIndex = ref(0);

const videoRecording = reactive({
  upload_id: null,
  video_id: null,
  video_url: null,
  thumbnail_url: null,
  title: null,
  description: null,
  tags: [],
  duration: null,
  status: null,
  created_at: null,
  updated_at: null,
});

const deviceList = reactive({ video: [], audio: [] });
const videoDeviceId = ref(null);
const audioDeviceId = ref(null);
const devicesLoaded = ref(false);
const audioLevel = ref(null);
const audioInterval = ref(null);
const cameraStream = ref(null);
const videoElement = ref(null); // html video element
const mediaRecorder = ref(null);
const audioContext = ref(null);
const recordingState = ref("idle");
const recordSlices = ref([]);
const countIn = ref(5);
const sliceUpdateInterval = ref(null);


const currentQuestion = computed(() => {
  return props.questions[currentQuestionIndex.value];
});

const isLastQuestion = computed(() => {
  return currentQuestionIndex.value === props.questions.length - 1;
});

function nextQuestion(){
  if(isLastQuestion.value){
    return;
  }
  currentQuestionIndex.value++;
}

const selectedVideoDevice = computed( () => {
  return deviceList.video
    .find( ({deviceId}) => deviceId === videoDeviceId.value );
});

const selectedAudioDevice = computed( () => {
  return deviceList.audio
    .find( ({deviceId}) => deviceId === audioDeviceId.value );
});

const showCounting = computed( () => {
  return recordingState.value === "counting" ||
    recordingState.value === "preparing-to-record";
});

const showVideo = computed( () => {
  return recordingState.value === "previewing" ||
         recordingState.value === "preparing-to-record" ||
         recordingState.value === "paused" ||
         recordingState.value === "counting" ||
         recordingState.value === "recording";
});

const showControls = computed( () => {
  return recordingState.value === "preparing-stream" ||
         recordingState.value === "previewing";
});

const showDuration = computed( () => {
  return recordingState.value === "recording" ||
         recordingState === "paused";
});

onMounted(() => {
  discoverDevices();
  startMediaStreams();
});

async function discoverDevices(){
  const devices = await navigator.mediaDevices.enumerateDevices();

  deviceList.video = [];
  deviceList.audio = [];

  deviceList.audio.push({
    deviceId: "none",
    kind: "audioinput",
    label: "No Audio",
  });

  const savedCameraId = window.localStorage.getItem("camera-device-id");
  const savedMicId = window.localStorage.getItem("mic-device-id");

  let tempVideoDevice = "";
  let tempAudioDevice = "";
  if (savedMicId === "none") {
    tempAudioDevice = "none";
  }

  devices.forEach((device) => {
    if (device.label && device.kind === "videoinput") {
      if (tempVideoDevice === "" || device.deviceId === savedCameraId) {
        tempVideoDevice = device.deviceId;
      }
      deviceList.video.push(device);
    }
    if (device.label && device.kind === "audioinput") {
      if (tempAudioDevice === "" || device.deviceId === savedMicId) {
        tempAudioDevice = device.deviceId;
      }
      deviceList.audio.push(device);
    }
  });

  devicesLoaded.value = true;
  audioDeviceId.value = tempAudioDevice;
  videoDeviceId.value = tempVideoDevice;
}

function onMicChange(device){
  window.localStorage.setItem("mic-device-id", device.deviceId);
  audioDeviceId.value = device.deviceId;
  stopStream();
  startMediaStreams();
};

function onCameraChange(device){
  window.localStorage.setItem("camera-device-id", device.deviceId);
  videoDeviceId.value = device.deviceId;
  stopStream();
  startMediaStreams();
};

async function startMediaStreams(){
  try {
    recordingState.value = "preparing-stream";
    await sleep(500);

    let savedVideoId = window.localStorage.getItem("camera-device-id");
    let savedAudioId = window.localStorage.getItem("mic-device-id");

    let videoConstraints = {
      aspectRatio: { ideal: 1.7777777778 },
      width: { ideal: 1920 },
      height: { ideal: 1080 },
    };
    let audioConstraints = {};

    if (savedVideoId) {
      videoDeviceId.value = savedVideoId;
      videoConstraints.deviceId = savedVideoId;
    }
    if (savedAudioId) {
      audioConstraints.deviceId = savedAudioId;
    }

    let videoStream = await navigator.mediaDevices.getUserMedia({
      video: {
        ...videoConstraints,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
      },
      audio: false,
    });

    cameraStream.value = videoStream;

    await Promise.allSettled([
    discoverDevices(),
          new Promise((r) => setTimeout(r, 250)),
        ]);

    // Add in the audio stream if there is one.
    let tracks = [];
    Array.prototype.push.apply(tracks, cameraStream.value.getTracks());

    if (savedAudioId !== "none") {
      let audioStream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      });
      setupAudioAnalyzer(audioStream);
      Array.prototype.push.apply(tracks, audioStream.getTracks());
    }

    cameraStream.value = new MediaStream(tracks);

    // Setup the video preview
    videoElement.value.srcObject = cameraStream.value;

    recordingState.value = "previewing";
  } catch (e) {
    recordingState.value = "error-starting-streams";
    console.error(e);
  }
}

// audio analyzer code heavily influenced by https://github.com/muxinc/stream.new
function setupAudioAnalyzer(stream){
  const audioContext = new AudioContext();
  const mediaStreamSource = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.3;
  analyser.fftSize = 1024;
  mediaStreamSource.connect(analyser);
  if (audioInterval.current) {
    clearInterval(audioInterval.current);
  }
  audioInterval.value = window.setInterval(() => {
    updateAudioLevels(analyser);
  }, 100);
}

function updateAudioLevels(analyser){
  // dataArray will give us an array of numbers ranging from 0 to 255
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(dataArray);
  const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
  // these values are between 0 - 255, we want the average and
  // to convert it into a value between 0 - 100
  const audioLevelValue = Math.round((average / 255) * 100) * 3.5;
  audioLevel.value = audioLevelValue;
};

async function startCountIn(){
  countIn.value = 5
  for (let i = 5; i > 0; i--) {
    countIn.value = i;
    document.title = `Start In: ${i}`;
    if (i > 1) {
      beep(20, 440, 50);
    }
    await sleep(1000);
  }
}

function updateRecordSlices(){
  recordSlices.value = recordSlices.value
    .map( slice => {
      if (slice.active) {
        slice.end = Date.now();
      }
      return slice;
    });
}

watch(recordingState, (newValue) => {
  if(newValue === "recording"){
    sliceUpdateInterval.value = setInterval(updateRecordSlices, 1000);
  } else if(sliceUpdateInterval.value){
    clearInterval(sliceUpdateInterval.value);
  }
})

function calculateDuration(recordSlices) {
  return recordSlices.reduce((acc, slice) => {
    const duration = acc + (slice.end - slice.start) / 1000;
    return duration;
  }, 0);
}

function secondsToTimecode(seconds){
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secs = seconds - hours * 3600 - minutes * 60;

  const hh = hours < 10 ? "0" + hours : hours;
  const mm = minutes < 10 ? "0" + minutes : minutes;
  let ss = "00";
  ss = secs < 10 ? "0" + secs.toFixed(0) : secs.toFixed(0);

  return `${mm}:${ss}`;
}

function beep(vol, freq, duration) {
  if (!audioContext.value) {
    return;
  }
  let oscillator = audioContext.value.createOscillator();
  let gain = audioContext.value.createGain();
  oscillator.connect(gain);
  oscillator.frequency.value = freq;
  oscillator.type = "sine";
  gain.connect(audioContext.value.destination);
  gain.gain.value = vol * 0.01;
  oscillator.start(audioContext.value.currentTime);
  oscillator.stop(audioContext.value.currentTime + duration * 0.001);
}

async function sleep(ms){
  return new Promise( resolve => setTimeout(resolve, ms) );
}

function pauseRecording() {
  if(!isLastQuestion.value){
    nextQuestion();
  }
  if (recordingState.value == "recording") {
    mediaRecorder.value?.pause();
    recordingState.value = "paused";
  }

  recordSlices.value = recordSlices.value
          .map( slice => ({...slice, active:false}));
};

function resumeRecording(){
  mediaRecorder.value?.resume();
  recordingState.value = "recording";
  recordSlices.value = [
    ...recordSlices.value,
    { start: Date.now(), end: Date.now(), active: true },
  ]
};

function stopRecording(){
  mediaRecorder.value?.stop();
};

async function startRecording(){
  if (cameraStream.value == null || cameraStream.value.active === false) {
    return;
  }

  audioContext.value = new AudioContext();

  const preferredMime = 'video/webm;codecs=vp9'
  const backupMime = 'video/webm;codecs=vp8,opus';
  const lastResortMime = 'video/mp4;codecs=avc1';

  let mimeType = preferredMime;
  let fileExtension = "webm";
  if (MediaRecorder.isTypeSupported(preferredMime)) {
    mimeType = preferredMime;
  } else if (MediaRecorder.isTypeSupported(backupMime)) {
    mimeType = backupMime;
  } else {
    fileExtension = "mp4";
    mimeType = lastResortMime;
  }

  await axios.post('/api/video-recording/start',{fileExtension})
        .then(({data}) => {
          Object.assign(videoRecording, data.videoRecording);
        }).catch((error) => {
            console.error(error);
        });

  recordingState.value = "preparing-to-record";
  await startCountIn();

  let recorderOptions = {
    audioBitsPerSecond: 128000,
    videoBitsPerSecond: 2500000,
    mimeType: mimeType,
  };

  mediaRecorder.value = new MediaRecorder(
    cameraStream.value,
    recorderOptions
  );

  mediaRecorder.value.onerror = (error) => {
    reset();
    console.error(error);
    recordingState.value = "error";
  };

  let parts = 0;
  let currentBlob = new Blob();
  let uploadPromises = [];
  let uploadError = false;
  const {
    upload_id: uploadId,
    id: videoId
  } = videoRecording;

  mediaRecorder.value.ondataavailable = async (event) => {

    console.log(`New Data: ${event.data.size} bytes`);

    // Append the new data to the current blob we are building up for upload.
    currentBlob = new Blob([currentBlob, event.data]);

    // If we are still recording and the blob isn't big enough just return
    // We'll upload next pass through.
    if (
      currentBlob.size < 6 * 1024 * 1024 &&
      mediaRecorder.value?.state === "recording"
    ) {
      console.log(
        `Blob is too small to upload: ${currentBlob.size} bytes - MediaRecorder: ${mediaRecorder.value?.state}`
      );
      return;
    }

    // If the blob is empty we don't want to upload it.
    if (currentBlob.size === 0) {
      console.log(
        `Blob size is 0, skipping upload - MediaRecorder: ${mediaRecorder.value?.state}`
      );
      return;
    }

    console.log(
      `Blob is big enough to upload ${currentBlob.size} bytes - MediaRecorder: ${mediaRecorder.value?.state}`
    );

    // Reset the current blob
    let blobToUpload = currentBlob;
    currentBlob = new Blob();

    console.info("Uploading part");
    // Upload what we just built
    parts++;
    let partNumber = parts;

    console.info(
      `START UPLOAD ${uploadId} PART: ${parts} SIZE: ${blobToUpload.size}`
    );

    try {
      let uploadPromise = uploadPart({
        uploadId,
        videoId,
        partNumber,
        payload: blobToUpload,
      });

      uploadPromises.push(uploadPromise);

      uploadPromise
        .then(() => {
          console.info(`UPLOAD SUCCESS ${uploadId} PART: ${partNumber}`);
        }).catch( err => {
          console.log(`UPLOAD ERROR ${uploadId} PART: ${partNumber}`, err);
        });

    } catch (err) {
      uploadError = true;
      console.error(err);
    }
  };

  mediaRecorder.value.onstop = async () => {
    console.log(
      `MediaRecorder Stopped - State: ${mediaRecorder.value?.state}`
    );

    document.title = "Recording Stopped";

    recordingState.value = "finishing-upload";

    console.info(`FINALIZE UPLOAD ${uploadId}`);

    if (uploadError == false) {
      console.info("WAITING FOR ALL PARTS TO FINISH");
      const uploadResults = await Promise.all(uploadPromises);
      console.info("ALL PARTS UPLOADED");
      try {
        await completeMultipart(videoId, uploadResults);
        console.info(`FINALIZE UPLOAD SUCCESS ${uploadId}`);
      } catch (err) {
        reset();
        console.error(err);
        recordingState.value = "error";
      }
    } else {
      reset();
      console.error("Error uploading video during recording");
      recordingState.value = "error";
    }
  };

  recordingState.value = "recording";
  document.title = "Recording!";
  mediaRecorder.value.start(1000);

  recordSlices.value = [
    {
      start: Date.now(),
      end: Date.now(),
      active: true,
    },
  ];
};

async function uploadPart({ uploadId, videoId, partNumber, payload }) {
  let url = await signUrl(videoId, partNumber);
  let etag = await uploadToAws(url, payload);
  return {uploadId, partNumber, payload, etag};
}

async function signUrl(videoId, partNumber, retryCount=0) {
  try{
    const {data} = await axios.post(
      `/api/video-recording/sign`,
      {videoId, partNumber: partNumber.toString()}
    );
    return data.url
  } catch(e){
    if(retryCount === 4){
      throw new Error("Signing Upload Request Failed.");
    }
    console.error(`${retryCount} Attempt: Signing Upload Request Failed.`);
    await sleep(250);
    retryCount++
    return signUrl(videoId, partNumber, retryCount);
  }
}

async function uploadToAws(url, payload, retryCount=0) {
  try{
    let uploadResult = await fetch(url, {
      method: "PUT",
      body: payload,
    })

    if(!uploadResult.ok){
      let message = await uploadResult.text();
      throw new Error(message);
    }
    let etag = uploadResult.headers.get("etag");
    if (!etag) {
      throw new Error("Upload failed to return etag");
    }
    return etag;
  } catch(e){
    if(retryCount === 4){
      throw new Error("Upload Video Part Failed");
    }
    console.error(`${retryCount} Attempt:  Upload Video Part Failed ${e.message}`);
    await sleep(250);
    retryCount++
    return uploadToAws(url, payload, retryCount);
  }
}

async function completeMultipart(videoId, uploadResults, retryCount=0){
  const parts = uploadResults
    .sort((a, b) => a.partNumber - b.partNumber)
    .map( ({etag, partNumber}) => ({ ETag: etag, PartNumber: partNumber}) );

  try
  {
    await axios.post(
      "/api/video-recording/complete",
      {videoId, parts}
    );

    // submit a form to onCompleteUrl
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", props.onCompleteUrl);

    const csrfInput = document.createElement("input");
    csrfInput.value = document.querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
      csrfInput.name = "_token";

    const videoIdInput = document.createElement("input");
    videoIdInput.value = videoId;
    videoIdInput.name = "videoId";

    form.appendChild(csrfInput);
    form.appendChild(videoIdInput);
    document.body.appendChild(form);
    form.submit();

  } catch(e){
    if(retryCount === 3){
      throw new Error("Completing Multipart Upload Failed");
    }
    console.error(`${retryCount} Attempt: Completing Multipart Upload Failed ${e.message}`);
    await sleep(250);
    retryCount++;
    return completeMultipart(videoId, uploadResults, retryCount);
  }
}

function reset() {
  if (audioInterval.value) {
    clearInterval(audioInterval.value);
  }
  stopStream();
  cleanupRecorder();
  recordingState.value = "idle";
}

function stopStream(){
  if (cameraStream.value) {
    cameraStream.value.getTracks()
      .forEach( track => track.stop() );
    cameraStream.value = null;
  }
};

function cleanupRecorder() {
  if (mediaRecorder.value) {
    if (mediaRecorder.value.state === "inactive") {
      console.log("MediaRecorder already inactive");
    } else {
      mediaRecorder.value.onstop = () => {
        console.log("MediaRecorder stopped");
      };
      mediaRecorder.value.stop();
    }
    mediaRecorder.value = null;
  }
};

</script>
